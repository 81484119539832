@import "node_modules/bootstrap/scss/bootstrap-grid.scss";

#root{
    height: 100%
}

.root-container{
    background-color: rgb(232, 232, 232);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;    
}

.form-heading{
    text-align: center;
    padding-top: 20px;
}

.header-container{
    text-align: center;
    padding: 20px 0px;
}

.title-name{
    display: flex;
    align-items: center;
}

.username{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0px;
    padding-top: 31px;
    padding-bottom: 10px;
}

.ant-layout-header{
    line-height: none !important;
}

#components-layout-demo-top .logo {
    width: 120px;
    height: 31px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px 24px 16px 0;
    float: left;
  }